<template>
  <div class="page-content">
    <div class="cfa-block p-4 mt-4 shadow-none">
      <div class="d-flex align-items-center justify-content-between px-0">
        <!-- <h5>Préférences de couleur</h5> -->
        <h5 class="mb-0">{{$t('color_preference')}}</h5>
        <!-- <a @click="setPrimaryColor('primary')" class="btn cfa-btn-primary d-flex align-items-center" style="font-weight: 400;">
              <span class="d-inline">ENREGISTRER LES COULEURS</span>
            </a> -->
        <input type="file" accept="image/*" ref="fileSelector" @change="uploadImage" style="display: none;">
      </div>
      <div class="px-0 ">
        <div class="tab-pane">
          <div v-if="type === 'HEX'">
            <br>
            <div class="tab-pane">
              <!-- Primary Color Selector -->
              <div style="display: inline-flex; align-items: center; min-width: 13em; justify-content: space-between"
                class="box">
                <label for="primaryColor">{{ $t('primary_color') }}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span type="text" id="primaryColor"></span>
                <input type="color" id="primaryColor" @change="setPrimaryColor('primary')" v-model="primary">
              </div>
              <br><br>

              <!-- Secondary Color Selector -->
              <div style="display: inline-flex; align-items: center; min-width: 13em; justify-content: space-between"
                class="box">
                <label for="secondaryColor">{{ $t('secondary_color') }}</label>&nbsp;&nbsp;
                <!-- <input type="text" id="secondaryColor" v-model="secondary"> -->
                <span type="text" id="secondaryColor"></span>
                <input type="color" id="secondaryColor" @change="setSecondaryColor('secondary')" v-model="secondary">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <app-logo v-if="true"></app-logo>
  </div>
</template>

<script>
import { getAPI, guestAPI } from '@/axios-api.js'
import AppLogo from "@/components/shared/AppLogo.vue";

export default {
  name: 'ParameterView',
  components: {
    AppLogo
  },
  data() {
    return {
      userData: null,
      alertString: null,
      firstname: '...',
      lastname: '...',
      phonePersonal: '...',
      phoneProfessional: '...',
      oldPassword: '',
      primary_color:'',
      secondary_color:'',
      silentRefresh: null,
      uploadPending: false,
      role: '',
      primary: "",
      secondary: "",
      type: 'HEX', // UI
      organisationData: [],
    }
  },
  methods: {
    changeType() {
      this.type = document.getElementById('selector').value;
    },
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${sessionStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.primary_color=this.organisationData.primary_color;
            this.secondary_color=this.organisationData.secondary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${sessionStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    changeLanguage(obj) {
      localStorage.setItem('language', obj.target.value)
    },
    retrieveUserData() {
      getAPI.get('accounts/users/me')
        .then(response => {
          if (response.status == 200) {
            this.userData = response.data.data
            this.firstname = this.userData.firstname
            this.lastname = this.userData.lastname
            this.role = this.userData.role
            this.phonePersonal = this.userData.phone
            this.phoneProfessional = this.userData.phone
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    tryChangePassword() {
      if (this.oldPassword.length > 2) {
        guestAPI.post('accounts/login',
          {
            email: this.userData.email,
            password: this.oldPassword,
          })
          .then(response => {
            if (response.status == 200) {
              // send password forget mail
              guestAPI.post('accounts/forgot-password', {
                email: this.userData.email,
              })
                .then(response => {
                  if (response.status == 200) {
                    //this.alertString = "Veuillez vérifier votre adresse e-mail pour changer le mot de passe.|alert-yes|6000::"+Date.now()
                  }
                  else {
                    //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                  }
                })
                .catch(() => {
                  //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                })
              // -------------------------
            }
            else {
              //this.alertString = `Votre mot de passe erroné. Etes-vous ${sessionStorage.firstname} ${sessionStorage.lastname}?|alert-no|6000::`+Date.now()
            }
          })
          .catch(() => {
            //this.alertString = `Votre mot de passe erroné. Etes-vous ${sessionStorage.firstname} ${sessionStorage.lastname}?|alert-no|6000::`+Date.now()
          })
      }
      else {
        //this.alertString = "Veuillez remplir correctement le champ.|alert-no|5000::"+Date.now()
      }
    },
    openFileSelector() {
      this.$refs.fileSelector.click();
    },
  },
  watch: {
    alertString(value) {
      this.$emit('alert', value)
    }
  },
  mounted() {
    this.retrieveOrg();
    this.activeColor();
  }
}
</script>

<style scoped>
.cfa-page {}

.cfa-block {
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);

  /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.form-group label {
  margin-bottom: 0.5em;
  font-weight: 400;
}
</style>