<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{$t('my_certificate')}}</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-if="!waitingAPIResponse" class="cfa-page">
        <div v-if="subscriptionData?.is_activated == true && subscriptionData !=  null">
          <div class="row">
            <div class="col-12 col-md-8 ">
              <div class="cfa-welcome ml-0">
                <div class=" d-flex align-items-center justify-content-between">
                  <h5 class="">{{$t('progress_status')}}</h5>
                </div>
                <p class="text-md-left text-justify mb-0" style="color: #132B4D">
                {{ $t('obtain_certificate') }}
                </p>
                <div>
                  <br>
                  <br>
                  <progress-bar 
                    :moduleName="`<b class='ff-bold' style='color: var(--primary-color);'>${$t('my_progress')}:</b>`"  
                    :percent="(dashboardDatas?.module_finish*100)/this.dashboardDatas?.numb_module || (dashboardDatas?.advancement_module*100)/this.dashboardDatas?.numb_module" backgroundColor="#d9d9d9" forgroundColor="var(--primary-color)" :label="Math.ceil(((dashboardDatas?.module_finish/this.dashboardDatas?.numb_module)*100) + ' %') || (dashboardDatas?.advancement_module  + ' %')"></progress-bar>
                  <br>
                  <progress-bar 
                   :moduleName="`<b class='ff-bold' style='color: var(--primary-color);'>${$t('quiz_score')}:</b>`"
                   :percent="(dashboardDatas?.quizz_finish*100)/this.dashboardDatas?.numb_module" backgroundColor="#d9d9d9" forgroundColor="red" :label="Math.ceil((dashboardDatas?.quizz_finish*100)/this.dashboardDatas?.numb_module)+'%'"></progress-bar>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 ">
              <div class="cfa-welcome cfa-bg-secondary py-4">
                <h5 class="mb-4">{{ $t('cyber_score') }}</h5>
                <div class="text-center">
                  <DoughnutChart
                    :classValue="'chartTextStyle3'"
                    :width="180"
                    :height="180"
                    :valueCountUp="true"
                    :percent=cyberscores.toString().substring(0,3)
                    :percent-to-display="cyberscores.toString().substring(0,3)"
                    :visibleValue="true"
                    foregroundColor="rgb(3, 57, 60)"
                    backgroundColor="#b9ebec"
                    :strokeWidth="20"
                  />
                </div>
                <p class="text-center m-2 mt-4">{{ $t('average_cyber_score') }} {{ cyberscores.toString().substring(0,3) }}%</p>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row">
            <div class="col-12 pe-0 pe-md-2">
              <div class="cfa-welcome ml-0">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class='ff-bold'>{{ $t('my_certificate') }}</h5>
                  <div v-if="userCertificate?.isAvailable" class="d-flex align-items-center justify-content-center">
                    <button class="btn cfa-btn2-primary mx-2">
                      <i class="fa fa-share"></i>
                      <span class="d-none d-md-inline">{{ $t('share') }}</span>
                    </button>
                    <button :disabled="isDisabled" @click="downloadCertificate" class="btn cfa-btn-primary mx-2">{{$t('download')}}</button>
                  </div>
                  <div v-else class="d-flex align-items-center justify-content-center">
                    <!--<button class="btn cfa-btn2-danger mx-2 disable">
                      <i class="fa fa-share"></i>
                      <span class="d-none d-md-inline">PARTAGER</span>
                    </button>-->
                    <share-button></share-button>
                    <button @click="downloadCertificate" class="mx-2 btn cfa-btn-primary d-flex align-items-center justify-content-end">
                        <i class="fa fa-download"></i>&nbsp;{{$t('download')}}
                    </button>
                    <div>
                    <!--<button @click="getCertificate" class="btn cfa-btn-danger">GENERER MON CERTIFICAT</button>-->
                  </div>
                  <!-- <div v-else>
                    <button class="btn cfa-btn-primary disable">GENERER MON CERTIFICAT</button>
                  </div> -->
                  </div>
                </div>

                <iframe v-if="false" class="col-12" :src="userCertificate?.data" title="CyberForceAcademy - Certificate"></iframe>
              </div>
            </div>
          </div>
          <div class="col-12 pe-0 pe-md-2">
              <div class="d-flex justify-content-end align-items-center mt-4 mr-4">
                  <!--<button @click="downloadCertificate" class="btn cfa-btn-primary d-flex align-items-center justify-content-end">
                      <i class="fa fa-download"></i>&nbsp;TELECHARGER
                  </button>-->
              </div>
          </div>

          <div class="w-100 pt-4 text-start mt-2 mb-3" id="detail-de-progression">
            <h1 class="cfa-section-title dark-color">{{ $t('details_progression') }}</h1>
          </div>

          <div class="col-12 pe-0 pe-md-2 ">
            <div class="cfa-welcome ml-0 gCorner-1100">
              <div class="py-2 row">
                <div class="text-start col-5 ps-4"><strong class="cfa-primary">MODULES</strong></div>
                <div class="text-start col-3"><strong class="cfa-primary">PROGRESSION</strong></div>
                <div class="d-none d-md-block text-center col-2"><strong class="cfa-primary">SCORE QUIZ</strong></div>
                <div class="text-end text-md-end pe-4 col-4 col-md-2"><strong class="cfa-primary">STATUT</strong></div>
              </div>
            </div>
            <div class="cfa-welcome ml-0 gCorner-0011" style="box-shadow: none; margin-top: 30px;margin-bottom:30px;">
              <!-- 01 -->
              <div v-for="module in module_progress" v-bind:key="module.id" class="py-4 row d-flex align-items-center cfa-certif-state-line" :class="(module.progress === 100) ? 'done' : ''">
                <div class="text-start col-5 fw-bold ff-bold ps-4" style='color: var(--primary-color); text-transform: uppercase;'>Module {{ module.module }}: {{ module.title }}</div>
                <div class="text-start col-3 d-flex">
                  <progress-bar-line :percent="module.progress"></progress-bar-line>
                </div>
                <div class="d-none d-md-block text-center col-2">
                  {{module.cyber_score}}
                </div>
                <div v-if="module.progress == 100" class="text-end col-4 col-md-2">
                  <button class="btn btn-discret cfa-btn-primary cfa-progress-status mx-2">{{ $t('to_end') }}</button>
                </div>
                <div v-else-if="module.progress > 0 && module.progress < 100" class="text-end col-4 col-md-2">
                  <button class="btn btn-discret cfa-btn-gold cfa-progress-status mx-2">{{ $t('in_progress') }}</button>
                </div>
                <div v-else class="text-end col-4 col-md-2">
                  <button class="btn btn-discret cfa-btn-light-primary cfa-progress-status mx-2">{{ $t('un_oppened') }}</button>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>

        <div v-else>
          <div class="card-body p-0 mt-2 mb-0">
          <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
              <div class="d-flex align-items-center justify-content-between w-100">
                  <div>
                     {{$t('obtain_certificate2')}}
                  </div>
                  <div class="">
                      <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                  </div>
              </div>
          </div>
      </div>
        </div>
        <div v-if="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
        </div>
      
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
import ProgressBar from '@/components/shared/chart/ProgressBar.vue'
import ProgressBarLine from '@/components/shared/chart/ProgressBarLine.vue'
import {getAPI} from "@/axios-api";
import ShareButton from "@/components/shared/share/ShareButton.vue";

export default {
  name: 'CertificateView',
  components: {
    CFAFooter,
    CFANav,
    DoughnutChart,
    ProgressBar,
    ProgressBarLine,
    ShareButton
  },
  data () {
    return {
      waitingAPIResponse: true,
      alertString: '',
      data:[],
      modules:[],
      cyberscores:0,
      dashboardData: null,
      dashboardDatas:null,
      module_progress: [],
      modulesData:null,
      module_covered: 0,
      userCertificate: null,
      allModules: [],
      isAvailable: false,
      quiz: {},
      subscriptionData: [],
    }
  },
  computed: {
    isDisabled() {
      // Disable the button if someValue equals "disable"
      return this.data[0].note <= 80;
    }
  },
  methods: {
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${sessionStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${sessionStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    async getAllQuiz() {
      try {
        this.waitingAPIResponse = true;
        const [response1] = await Promise.all([
          getAPI.get(`stat_quizz?user_id=${sessionStorage.user}`),
        ]);
        if(response1.status == 200) {
          this.data=response1.data;
          console.log("Ici: ", this.data);
          this.quiz = this.data[0].quizz;
          this.cyberscores=this.data[0].note;

          this.getCertificate();
          console.log("Quiz id: ", this.quiz.id)
          if (this.quiz !== null) {
            //this.getQuizStat();
          }
          //this.getQuizStat();
          
          this.waitingAPIResponse = false
        }
        else {
          this.waitingAPIResponse = false
          //this.alertString = `Une premiere erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        console.log("Une erreur est survenue dans getAllQuiz :", e);
        //this.alertString = `Une seconde erreur est survenu|alert-no|4000::`+Date.now()
      }
    },
    retrieveUserSubscription () {
            this.waitingAPIResponse = true
            getAPI.get(`subscriptions/find-subscription`).then(response => {
              if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
                this.remain_days = this.subscriptionData.remain_days

                const created_at = new Date(this.subscriptionData.created_at)
                created_at.setDate(created_at.getDate() + 30);
                
                const expirationDate = created_at.toISOString();

                this.expiration_date = expirationDate
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
      },
      getCertificate () {
      this.waitingAPIResponse = true
      console.log("SESSION STORAGE USER: ", sessionStorage.user)
      // if(this.quiz){
        getAPI.get(`certificats/user/${sessionStorage.user}/${this.quiz.id}`).then(response => {
          if(response.status == 200) {
            this.userCertificate = response.data
            this.isAvailable = this.userCertificate.isAvailable;
            console.log("Dispo: ", this.isAvailable);
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        }).catch(error => {
          if (error.response) {
            ////this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            this.isAvailable = error.response.data.isAvailable;
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      
    },
    downloadCertificate() {
        //this.waitingAPIResponse = true
        this.getCertificate();
          getAPI.get('certificats/download', { responseType: 'arraybuffer' }).then(response => {

            console.log("USer: ", )
            if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${sessionStorage.lastname}_${sessionStorage.firstname}_certificate.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            //let msg = 'Oups ! Something went wrong.';
            //this.alertString = `${msg}|alert-no|5000::` + Date.now();
        }

        setTimeout(() => {
            this.waitingAPIResponse = false;
        }, 2000);
        }).catch(error => {
          console.log("Erreur: ", error);
            //let msg = "Votre certificat n'est pas encore disponible"
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            if (error.response) {
                ////this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
        })
    },
    getPercent(value, maxValue) {
        if (maxValue === 0) {
            return 0;
        }
        return Math.min(100, Math.round((value / maxValue) * 100));
    },
    removeDuplicateModules() {
    const uniqueModules = new Set();
    // Filter module_progress to remove duplicates based on module number
    this.module_progress = this.module_progress.filter(module => {
        if (!uniqueModules.has(module.module)) {
            uniqueModules.add(module.module); // If it's not in the Set, add it
            return true; // Keep this module
        }
        return false; // Filter out this duplicate
    });

    console.log("Filtered Module Progress:", this.module_progress);
},
    async stats() {
  try {
    const response = await getAPI.get('modules/all');
    if (response.status === 200) {
      console.log("dataModule:", response.data.data);
      this.modulesData = response.data.data;
      const org = sessionStorage.getItem('organisation');

      // Flatten and assign data to modules list
      this.modules = this.modulesData.map(module => {
        const isVisible = module.is_visible_for.some(uuid => uuid === org);
        return {
          id: module.id,
          name: module.title,
          is_visible_for: module.is_visible_for,
          visible: isVisible
        };
      });

      console.log("Modules: ", this.modules[0]);
    }
  } catch (error) {
    console.error('Error fetching modules:', error);
  }

  // Array to store module IDs with different messages
  const differentMessageModules = [];

  // Loop through the modules to check their status
  for (let i = 0; i < this.modules.length; i++) {
    try {
      const moduleStatusResponse = await getAPI.get(`stat_module/user/${sessionStorage.user}/${this.modules[i].id}/`);

      if (moduleStatusResponse.status === 200) {
        if (moduleStatusResponse.message === 'Updated Sucessfully') {
          let progress = moduleStatusResponse.data;
          this.module_covered = moduleStatusResponse.data.length;
          console.log(progress);

          this.module_progress = this.module_progress || []; // Ensure it's initialized

          for (let j = 0; j < progress.length; j++) {
            this.module_progress.push({
              'module': progress[j].module.number,
              'title': progress[j].module.title,
              'progress': (progress[j].progression == 0) ? 1 : progress[j].progression,
              'cyber_score': progress[j].cyberscore
            });
          }
        } else {
          // If message is different from 'Updated Sucessfully', store module ID
          differentMessageModules.push(this.modules[i].id);

          // Optionally fetch additional module data if needed
          const moduleResponse = await getAPI.get(`modules/${this.modules[i].id}/`);
          if (moduleResponse.status === 200) {
            console.log(moduleResponse.data);
            this.module_progress.push({
              'module': moduleResponse.data.number,
              'title': moduleResponse.data.title,
              'progress': 0,
              'cyber_score': 0
            });
          }
        }
        this.removeDuplicateModules();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // If "not found" error, set progress and cyber_score to 0
        console.log(`Module ${this.modules[i].id} not found for user. Setting defaults.`);
      } else {
        console.error('Error fetching module stats:', error);
      }
    }
  }

  // Log or process the modules that returned a different message
  console.log("Modules with different messages:", differentMessageModules);
},

    postCertificate () {
      this.waitingAPIResponse = true
      getAPI.post(`certificats/`).then(response => {
        if(response.status == 201) {
          this.userCertificate = response.data
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    }
  },
  async mounted() {
    this.getCertificate();
  this.retrieveUserSubscription();
    this.getAllQuiz();
      this.waitingAPIResponse = true
      await getAPI.get(`other/cyberscores-global`).then(response => {
          if (response.status == 201) {
              this.dashboardData = response.data.data
              console.log("Data: ", this.dashboardData)
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })
      await getAPI.get(`other/dashboard`).then(response => {
          if (response.status == 200) {
              this.dashboardDatas = response.data.data
              console.log("Data: ", this.dashboardDatas)
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })

      await getAPI.get('modules/all')
          .then(response => {
              if(response.status === 200) {
                  let modulesData = response.data
                  this.allModules = modulesData.data
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
          })
          .catch(error => {
              if (error.response) {
                  //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                  //let msg = 'The request was made but no response was received. Please check your network.'
                  //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
          })

      this.waitingAPIResponse = true
      await getAPI.get(`stat_module?user_id=${sessionStorage.user}`).then(response => {
          if (response.status === 200) {
              let progress = response.data
              this.module_covered = response.data.length
              for (let i = 0; i <= progress.length; i++) {
                  this.module_progress.push({
                      module: progress[i].module.number,
                      title: progress[i].module.title,
                      progress: (progress[i].progression == 0) ? 1 : progress[i].progression,
                      score_quizz: 0
                  })
              }
              // for (let i = this.module_progress.length; i < this.dashboardData?.numb_module; i++) {
              //     this.module_progress.push({module: i + 1, title: this.allModules[i].title, progress: 0, score_quizz: 0})
              // }
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })

      
      this.stats();
  }

}
</script>

<style scoped>
.cfa-page{

}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
   
}
.cfa-certif-state-line{
    margin-left: -0.5em;
    margin-right: -0.5em;
}
.done{
    background-color: var(--primary-light-color);
}
.cfa-certif-state-line:hover{
    /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  transition: all .4s ease-in;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  /*background-color: #0a9da5;*/
}
.cfa-progress-status{
  min-width: 7em;
}
.cfa-btn-light-primary {
  background-color: var(--primary-light-color-2);
  color: #132B4D;
  transition: all ease-in .2s;
}
</style>
